import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import loadable from '@loadable/component';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import ExploreMenu from '@components/fixed/exploreMenu';
import { PageMeta } from '@components/pageMeta';
import { wrapper } from '../css/base/backgroundVideo.module.css';

const Player = loadable(() => import('react-player'));

const variants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 2,
      delay: 1,
      ease: 'easeInOut',
    },
  },
};

const serviceVariants = {
  intro: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const IndexPage = ({ data: { page, services } }) => {
  const { modules, banner, seo } = page || {};
  const { nodes } = services || {};
  const breakpoints = useBreakpoint();

  const [activeService, setActiveService] = useState(0);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeService < nodes.length - 1) {
        setActiveService(activeService + 1);
      } else {
        setActiveService(0);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [activeService]);

  return (
    <Layout unPinHeader={true} isHome={true}>
      <PageMeta {...seo} />

      <div className='h-[50vh] md:h-full w-full relative flex flex-col '>
        <div
          className={`fixed z-0 w-full h-full md:h-screen top-0 left-0  ${wrapper}`}>
          <Player
            url={banner?.video}
            playing
            // onPlay={() => setWait(false)}
            loop
            playsInline
            muted
            className=' w-full h-full top-0 left-0 object-cover brightness-50'
            width='100%'
            height='100%'
          />
        </div>

        {/* <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50" /> */}
        {breakpoints.md && (
          <div className='h-screen  z-10 px-gutter flex pb-20 w-full '>
            <div className='text-white  self-end relative w-[2000px]'>
              <motion.div
                variants={variants}
                initial='hidden'
                animate='visible'
                className='mb-6 '>
                <h1 className='blockH1  z-40 '>{banner?.bigText}</h1>
                <div className='cover h-[140px] lg:h-[100px]'>
                  <AnimatePresence className=''>
                    <motion.h2
                      initial={{ opacity: 0, y: 100 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: { duration: 0.5, delay: 0.5 },
                      }}
                      exit={{ opacity: 0, y: -80 }}
                      transition={{ duration: 1, ease: 'easeInOut' }}
                      key={activeService}
                      className='absolute top-14 md:top-[5.5rem] left-0 blockH1 z-30 pt-4   overflow-auto'>
                      {nodes[activeService]?.title}
                    </motion.h2>
                  </AnimatePresence>
                </div>
              </motion.div>
              {/* removing this anchor scroll button untill further notice */}
              {/* <StandardButton aLink="services" buttonName="View Services" /> */}
            </div>
          </div>
        )}
        {breakpoints.md && (
          <motion.div
            ref={ref}
            variants={serviceVariants}
            initial='intro'
            animate={inView ? 'visible' : 'intro'}
            className=' z-10 px-gutter  py-space-l grid grid-cols-2'>
            <h1 className='text-white blockH5 col-start-2 '>
              {banner?.smallText}
            </h1>
          </motion.div>
        )}
      </div>
      {!breakpoints.md && (
        <div className=' h-full bg-green-tint relative z-10 px-gutter flex  pt-9 w-full '>
          <div className='text-green  self-end relative w-[2000px]'>
            <motion.div
              variants={variants}
              initial='hidden'
              animate='visible'
              className='mb-6 '>
              <h1 className='blockH1  z-40 '>{banner?.bigText}</h1>
              <div className='cover h-[100px]'>
                <AnimatePresence className=''>
                  <motion.h1
                    initial={{ opacity: 0, y: 100 }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.5, delay: 0.5 },
                    }}
                    exit={{ opacity: 0, y: -80 }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    key={activeService}
                    className='absolute top-10 left-0 blockH1 z-30 pt-4  overflow-auto '>
                    {nodes[activeService]?.title}
                  </motion.h1>
                </AnimatePresence>
              </div>
            </motion.div>
            {/* removing this anchor scroll button untill further notice */}
            {/* <StandardButton
              aLink="services"
              buttonName="View Services"
              colour="Blue"
            /> */}
          </div>
        </div>
      )}
      {!breakpoints.md && (
        <div className='relative bg-green-tint z-10 px-gutter  py-space-m  '>
          <h1 className='text-blue blockH5  '>{banner?.smallText}</h1>
        </div>
      )}

      {modules && (
        <div className='relative'>
          <ModuleZone {...modules} />
        </div>
      )}

      <ExploreMenu />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      banner {
        bigText
        smallText
        # bgImage {
        #   ...ImageWithPreview
        # }
        video
      }

      modules {
        ...HomeModules
      }
    }
    services: allSanityService(sort: { fields: orderRank, order: ASC }) {
      nodes {
        title
      }
    }
  }
`;
